import React, {FormEvent, useEffect, useRef, useState} from "react";
import {Button, Col, Form, Row, Spinner, Image} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "../../redux/states";
import {remoteConfig} from "../../services/firebase.service";
import {
  AppState,
  CheckAppLogoExist,
  CheckContentProviderIdExist,
  CheckInstitutionsExist,
  CheckSignUpReferralExist,
  DEFAULT_TIMER,
  getAppLogo,
  getAppName,
  getContentProviderId,
  getInstitutions,
  getKey, getLanguageByDefault,
  getSignUpReferral, InfoLogger,
} from "../../services/app.service";
import constants from "../../utils/constants";
import Actions from "../../redux/actions";
import {loadReCaptcha, ReCaptcha} from "react-recaptcha-v3";
import {RouteEndpoints} from "../../services/route.service";
import NotifyAlert from "../../components/NotifyAlert";
import OAuth from "./OAuth";
import Languages from "../../components/Languages";
import {Countries} from "../../utils/constants";
import logo from "../../assets/images/favicon.png";

function SignUp() {
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [checkPasswordConfirm, setCheckPasswordConfirm] = useState(false);
  const [checkPasswordLength, setCheckPasswordLength] = useState(false);
  const [institute, setInstitute] = useState("");
  const [about, setAbout] = useState("");
  const [referral_id, setReferral_id] = useState(
    CheckSignUpReferralExist() ? getSignUpReferral() : ""
  );
  const recaptchaRef = useRef(null);
  const [token, setToken] = useState("");
  const pageRef = useRef<HTMLHeadingElement>(null);
  const dispatch = useDispatch();
  const { state, message } = useSelector(
    (state: IRootState) => state.auth.register
  );
  const getLanguage = getLanguageByDefault()
  const { recaptchaSiteKey } = useSelector(
    (state: IRootState) => state.setting.keys
  );

  const [loadCaptcha, setLoadCaptcha] = useState(-3);

  const recaptchaSiteKey1 =
    remoteConfig.getValue(getKey(constants.RECAPTCHA_SITE_KEY)).asString() ??
    "";

  useEffect(() => {
    if (!recaptchaSiteKey && recaptchaSiteKey1) {
      dispatch(Actions.setting.setRecaptchaSiteKey(recaptchaSiteKey1));
    }
  }, [recaptchaSiteKey1]);

  useEffect(() => {
    try {
      loadRC();
    } catch (e) {
      console.error("captcha", e);
      setLoadCaptcha(loadCaptcha + 1);
    }
  }, [recaptchaSiteKey, loadCaptcha]);

  useEffect(() => {
    if (state === AppState.ERROR || state === AppState.ERROR_INPUT) {
      toTop();
    }
  }, [state]);

  const loadRC = () => {
    if (loadCaptcha < 0 && recaptchaSiteKey.length > 0) {
      // @ts-ignore
      loadReCaptcha(recaptchaSiteKey, () => {
        InfoLogger("captcha ready");
        setLoadCaptcha(1);
      });
    }
  };
  

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    loadRC();
    e.preventDefault();
    if (password === confirmPassword) {
      dispatch(
        Actions.auth.signUp({
          about,
          city,
          country,
          institute,
          referral_id,
          first_name,
          last_name,
          username,
          email,
          password,
          token: token ?? 'yes',
          confirmPassword
        })
      );
    }
    else {
      dispatch(
        Actions.auth.setRegisterMessage("Confirm password mismatch")
      );
      dispatch(Actions.auth.setRegisterState(AppState.ERROR_INPUT));
    }
  };

  const toTop = () => {
    // @ts-ignore
    pageRef.current.scrollTo(0, 0);
  };
  return (
    <>
      <div
        className="sign-in-from vh-100 overflow-auto hide-scrollbar"
        ref={pageRef}
      >
        <h1 className="mb-0">
          {getLanguage.signUp}
        </h1>
        {/*<p>Enter your email address and password to access admin panel.</p>*/}
        {(state === AppState.ERROR || state === AppState.ERROR_INPUT) && (
          <NotifyAlert
            message={message}
            state={AppState.ERROR}
            timer={DEFAULT_TIMER}
          />
        )}
        {(state === AppState.LOADING) && (
          <NotifyAlert
            message={message}
            state={AppState.WARNING}
            timer={DEFAULT_TIMER}
          />
        )}
        <Form onSubmit={handleSubmit} style={{marginTop: '1.5rem'}}>
          <Row>
            <Col md="6" style={{marginTop: '1.12rem'}}>
              <Form.Label className="mb-0 h6">
                {getLanguage.firstName}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                required
                className={`${(state === AppState.ERROR_INPUT && message.includes('first_name')) && "is-invalid"}`}
                onChange={(event) => setFirst_name(event.target.value)}
                style={{borderRadius: 8}}
              />
            </Col>
            <Col md="6" style={{marginTop: '1.12rem'}}>
              <Form.Label className="mb-0">
                <Languages langKey="lastName" />
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                required
                className={`${(state === AppState.ERROR_INPUT && message.includes('last_name')) && "is-invalid"}`}
                onChange={(event) => setLast_name(event.target.value)}
                style={{borderRadius: 8}}
              />
            </Col>
            <Col md="12" style={{marginTop: '1.12rem'}}>
              <Form.Label className="mb-0 h6">
                <Languages langKey="username" />
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                required
                className={`${(state === AppState.ERROR_INPUT && message.includes('username')) && "is-invalid"}`}
                onChange={(event) => setUsername(event.target.value)}
                style={{borderRadius: 8}}
              />
            </Col>
            <Col md="12" style={{marginTop: '1.12rem'}}>
              <Form.Label className="mb-0 h6">
                <Languages langKey="email" />
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="email"
                required
                className={`${(state === AppState.ERROR_INPUT && message.includes('email')) && "is-invalid"}`}
                onChange={(event) => setEmail(event.target.value)}
                style={{borderRadius: 8}}
              />
            </Col>
            <Col md="6" style={{marginTop: '1.12rem'}}>
              <Form.Label className="mb-0 h6">
                <Languages langKey="country" />
                <span className="text-danger">*</span>
              </Form.Label>
              {/* <Form.Control
                type="text"
                required
                className={`${state === AppState.ERROR_INPUT && "is-invalid"}`}
                onChange={(event) => setCountry(event.target.value)}
              /> */}
              <Form.Select
                className={`form-control ${
                  (state === AppState.ERROR_INPUT && message.includes('country')) && "is-invalid"
                }`}
                onChange={({ target: { value } }) => setCountry(value)}
                required
                defaultValue={country}
                style={{borderRadius: 8}}
              >
                <option disabled value={''}>
                  --Select Country--
                </option>
                {Countries.map((country: string,i) => (
                  <option value={country} key={i}> {country}</option>
                ))}
              </Form.Select>
            </Col>
            <Col md="6" style={{marginTop: '1.12rem'}}>
              <Form.Label className="mb-0 h6">
                <Languages langKey="city" />
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                required
                className={`${(state === AppState.ERROR_INPUT && message.includes('city')) && "is-invalid"}`}
                onChange={(event) => setCity(event.target.value)}
                style={{borderRadius: 8}}
              />
            </Col>
            <Col md="12" style={{marginTop: '1.12rem'}}>
              <Form.Label className="mb-0 h6">
                <Languages langKey="password" />
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="password"
                required
                className={`${
                  ((state === AppState.ERROR_INPUT && message.includes('password')) || checkPasswordLength) &&
                  "is-invalid"
                }`}
                onChange={(e) => {
                  setPassword(e.target.value);
                  if (e.target.value.length < 8) {
                    setCheckPasswordLength(true);
                  } else {
                    setCheckPasswordLength(false);
                  }
                  if (e.target.value === confirmPassword) {
                    setCheckPasswordConfirm(false);
                  } else {
                    setCheckPasswordConfirm(true);
                  }
                }}
                style={{borderRadius: 8}}
              />
              {checkPasswordLength && (
                <Form.Control.Feedback type="invalid">
                  <Languages langKey="passwordError" />
                </Form.Control.Feedback>
              )}
            </Col>
            <Col md="12" style={{marginTop: '1.12rem'}}>
              <Form.Label className="mb-0 h6">
                <Languages langKey="confirmPassword" />
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="password"
                required
                className={`${
                  ((state === AppState.ERROR_INPUT && message.includes('password')) || checkPasswordConfirm) &&
                  "is-invalid"
                }`}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  if (e.target.value === password) {
                    setCheckPasswordConfirm(false);
                  } else {
                    setCheckPasswordConfirm(true);
                  }
                }}
                style={{borderRadius: 8}}
              />
            </Col>
            <Col md="12" style={{marginTop: '1.12rem'}}>
              <Form.Label className="mb-0 h6">
                {" "}
                <Languages langKey="institute" />
              </Form.Label>
              {!CheckInstitutionsExist() ? (
                <Form.Control
                  type="text"
                  onChange={(event) => setInstitute(event.target.value)}
                  style={{borderRadius: 8}}
                />
              ) : (
                <Form.Select
                  onChange={(event) => setInstitute(event.target.value)}
                  defaultValue={institute}
                  style={{borderRadius: 8}}
                >
                  <option disabled value={''}>
                    --Select Institute--
                  </option>
                  {getInstitutions().map((item: string, i: number) => (
                    <option key={i}>{item}</option>
                  ))}
                </Form.Select>
              )}
            </Col>
            {!CheckSignUpReferralExist && (
              <Col md="12" style={{marginTop: '1.12rem'}}>
                <Form.Label className="mb-0 h6">
                  {" "}
                  <Languages langKey="referralID" />
                </Form.Label>
                <Form.Control
                  type="text"
                  onChange={(event) => setReferral_id(event.target.value)}
                  style={{borderRadius: 8}}
                />
              </Col>
            )}
            {CheckContentProviderIdExist() &&
            getContentProviderId() === "74" ? (
              <Col md="12" style={{marginTop: '1.12rem'}}>
                <Form.Label className="mb-0 h6">Position</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(event) => setAbout(event.target.value)}
                  style={{borderRadius: 8}}
                />
              </Col>
            ) : (
              <Col md="12" style={{marginTop: '1.12rem'}}>
                <Form.Label className="mb-0 h6">
                  {" "}
                  <Languages langKey="about" />
                </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder={
                    getLanguage.tellUsAboutYourself
                  }
                  onChange={(event) => setAbout(event.target.value)}
                  style={{borderRadius: 8}}
                />
              </Col>
            )}
          </Row>
          <div className="d-inline-block w-100" style={{marginTop: '1.5rem'}}>
            {/*<Form.Check className="d-inline-block mt-1 pt-1">
                                    <Form.Check.Input type="checkbox" className="me-2" id="customCheck1" required/>
                                    <Form.Check.Label>I accept
                                       Terms and Conditions
                                       <Link to="#">Terms and Conditions</Link>
                                    </Form.Check.Label>
                              </Form.Check>*/}
            <Button variant="primary" type="submit" className="w-100 mb-0 mt-0" style={{backgroundColor: '#DF6438', borderColor: '#DF6438', height: 50,borderRadius: 8}}>
              {state === AppState.LOADING && (
                <Spinner animation={"border"} size={"sm"} className="me-1" />
              )}
              <Languages langKey="signUp" />
            </Button>
          </div>
          <div className="sign-info d-flex flex-column mb-5 pb-5">

            {!CheckContentProviderIdExist() && <OAuth />}

            <Link to={RouteEndpoints.SIGN_IN} className="dark-color d-flex line-height-2 justify-content-center" style={{ fontWeight: 'bold', marginTop: '1.5rem'}}>
            <Languages langKey="gotAnAccount" /> ?{" "}
                <Languages langKey="signIn" />
            </Link>
            <div className="w-100 d-flex justify-content-center mt-4">
              <Link to="#" className="text-dark" style={{fontWeight: 'bold'}}>
                  <Image src={CheckAppLogoExist() ? getAppLogo() : logo} style={{width: 30}} alt="logo" /> {getAppName()}
                </Link>
              </div>
          </div>
        </Form>
      </div>
      {loadCaptcha && (
        <ReCaptcha
          ref={recaptchaRef}
          action="submit"
          sitekey={recaptchaSiteKey}
          verifyCallback={(e) => setToken(e)}
        />
      )}
    </>
  );
}

export default SignUp;
